import React, { useContext, useEffect, useState } from "react";
import { OnmaxContext } from "../OnmaxContext";
import { useNavigate } from 'react-router-dom';

export default function OverviewPage() {
  const { toastError,
    toastSuccess,setaccount
  } = useContext(OnmaxContext);
  const [oAddr, setoAddr] = useState(null);
  var navigate = useNavigate();

  const getDta = async (type) => {
    if (!oAddr) {
      toastError("Enter address")
      return
    }
    setaccount(oAddr)
    navigate('/dashboard')
  };
  return (
    <>
      <div className="body-bg">
        <section className="nftmax-adashboard nftmax-show">
          <div className="container">
            <div className="row align-items-end justify-content-center">

              <div className="col-lg-12 col-md-12 col-12 nftmax-main__colum">
                <div className="nftmax-body">
                  <div className="nftmax-inner__heading">
                    <h2 className="nftmax-inner__page-title">Overview</h2>
                  </div>
                  <div className="nftmax-dsinner">
                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="nftmax-personals">
                          <div className="nftmax-ptab">
                            <div className="nftmax-ptabs__inner mt-4">
                              <div className="tab-content" id="nav-tabContent">
                                <div
                                  className="tab-pane fade show active"
                                  id="id1"
                                  role="tabpanel"
                                >
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="nftmax-ptabs__separa">
                                        <div className="nftmax-ptabs__form-main">
                                          <div className="nftmax__item-form--group">
                                            <div className="d-flex justify-content-between">
                                              <label className="nftmax__item-label">
                                                Enter Address 
                                              </label>
                                              
                                            </div>
                                            <input
                                              className="nftmax__item-input"
                                              type="text"
                                              placeholder="Enter Address"
                                              onChange={(e) =>
                                                setoAddr(e.target.value)
                                              }
                                            />
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="nftmax__item-button--group nftmax__ptabs-bottom">
                                    <button
                                      className="nftmax__item-button--single nftmax-btn nftmax-btn__bordered bg radius "
                                      type="button"
                                      onClick={() => getDta()}
                                    >
                                      Overview
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>
      </div>
    </>
  );
}
