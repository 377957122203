import React, { useContext, useEffect, useState } from "react";
import Sidebar from "./Comman/Sidebar";
import Header from "./Comman/Header";
import { OnmaxContext } from "../OnmaxContext";
import axios from "axios";
import Pagination from "./Comman/Pagination";

export default function CommunityRoyalty() {
    const { account,copyaddress,formatAddress} = useContext(OnmaxContext);
    const [data, setdata] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);
  
    const getData = async () => {
      if (!account) {
        return;
      }
      setisLoading(true);

      await axios.post(process.env.REACT_APP_BACKEND_LINK, {
          method: "rankincome",
          submethod: "getbyid",
          address: account,
          key: process.env.REACT_APP_KEY,
          page: currentPage,
          pageSize: pageSize  
        })
        .then((res) => {
          // console.log("res",res.data);
          // var dedata = decryptData(res.data);
          setisLoading(false);

          if (res.data.error) {
            return "";
          }

          setdata(res.data.data);
          setPages(Math.ceil(res.data.dataLength / pageSize));

        });
    };
    useEffect(() => {
      getData();
    }, [account,pages, pageSize, currentPage]);
  return (
    <>
      <div className="body-bg">
        <Sidebar />
        <Header />
        <section className="nftmax-adashboard nftmax-show">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12 col-12 nftmax-main__colum">
                <div className="nftmax-body">
                  <div className="nftmax-dsinner">
                    <div className="nftmax-table mg-top-40">
                      <div className="nftmax-table__heading">
                        <h3 className="nftmax-table__title mb-0">
                          Rank Reward
                        </h3>
                      </div>
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="table_1"
                          role="tabpanel"
                          aria-labelledby="table_1"
                        >
                          <table
                            id="nftmax-table__main"
                            className="nftmax-table__main nftmax-table__main-v1"
                          >
                            <thead className="nftmax-table__head">
                              <tr>
                                <th className="nftmax-table__column-1 nftmax-table__h1">
                                  Sr
                                </th>
                                <th className="nftmax-table__column-2 nftmax-table__h2">
                                  Rank
                                </th>
                                <th className="nftmax-table__column-2 nftmax-table__h2">
                                  From
                                </th>
                                <th className="nftmax-table__column-2 nftmax-table__h2">
                                Reward
                                </th>
                                <th className="nftmax-table__column-2 nftmax-table__h2">
                                  Staking
                                </th>
                                <th className="nftmax-table__column-3 nftmax-table__h3">
                                  Status
                                </th>
                                <th className="nftmax-table__column-4 nftmax-table__h4">
                                  Time
                                </th>
                              </tr>
                            </thead>
                            {isLoading ? "Data is loading" : ""}
                            {!isLoading ? (
                              !data.length ? (
                                <tr className="text-center">
                                  <td className="text-center" colSpan={5}>
                                    <span className="w-100">No data found</span>
                                  </td>
                                </tr>
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}
                            <tbody className="nftmax-table__body">
                              {data.map((ele, i) => {
                                return (
                                  <tr>
                                    <td className="nftmax-table__column-1 nftmax-table__data-1">
                                      <p className="nftmax-table__text nftmax-table__bid-text">
                                        {ele.id}
                                      </p>
                                    </td>
                                    <td className="nftmax-table__column-2 nftmax-table__data-2">
                                      <div className="nftmax-table__amount nftmax-table__text-one">
                                        <span className="nftmax-table__text">
                                          {ele.rank}
                                        </span>
                                      </div>
                                    </td>
                                    <td className="nftmax-table__column-2 nftmax-table__data-2">
                                      <div className="nftmax-table__amount nftmax-table__text-one">
                                        <span className="nftmax-table__text" onClick={() =>
                                        copyaddress(ele.from)}>
                                        {formatAddress(ele.from)} <i className="fa fa-copy"></i>
                                        </span>
                                      </div>
                                    </td>
                                    <td className="nftmax-table__column-2 nftmax-table__data-2">
                                      <div className="nftmax-table__amount nftmax-table__text-one">
                                        <span className="nftmax-table__text">
                                          $ {ele.income} ({ele.rew_per} %)
                                        </span>
                                      </div>
                                    </td>
                                    <td className="nftmax-table__column-2 nftmax-table__data-2">
                                      <div className="nftmax-table__amount nftmax-table__text-one">
                                        <span className="nftmax-table__text">
                                          $ {ele.s_amount} 
                                        </span>
                                      </div>
                                    </td>
                                    <td className="nftmax-table__column-3 nftmax-table__data-3">
                                      <p className="nftmax-table__text nftmax-table__bid-text text-danger">
                                        {ele.flushed ? "Flushed" : ""}
                                        <br/>
                                        {ele.gracing   ? "Gracing" : ""}
                                      </p>
                                    </td>
                                    <td className="nftmax-table__column-4 nftmax-table__data-4">
                                      <div className="nftmax-table__text">
                                        {ele.createdAt}
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                          <Pagination
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            pages={pages}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
