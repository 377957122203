import React, { useContext } from "react";
import Header from "./Comman/Header";
import Sidebar from "./Comman/Sidebar";
import { OnmaxContext } from "../OnmaxContext";
import { Link } from "react-router-dom";

export default function Profile() {
  const { dbuser } = useContext(OnmaxContext);

  return (
    <>
      <div className="body-bg">
        <Sidebar />
        <Header />
        <section className="nftmax-adashboard nftmax-show">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9 col-12 nftmax-main__column">
                <div className="nftmax-body">
                  <div className="nftmax-dsinner">
                    <div className="nftmax-userprofile mg-top-40">
                      <div className="nftmax-userprofile__header">
                        <img src="assets/img/onmax-bnr.jpg" alt="#" />
                      </div>
                      <div className="nftmax-userprofile__user">
                        <div className="nftmax-userprofile__content">
                          <div className="nftmax-userprofile__thumb">
                            <div className="animated-image-container">
                              <img
                                src="assets/img/logo-footer.png"
                                className="animated-image"
                                alt="#"
                              />
                            </div>
                          </div>
                          <div className="nftmax-userprofile__info ">
                            <h4 className="nftmax-userprofile__info-title text-light">
                              {dbuser !== null
                                ? dbuser.name !== null
                                  ? dbuser.name
                                  : "ONMAXIER"
                                : "ONMAXIER"}
                            </h4>
                            <p className="nftmax-userprofile__info-text">
                              Onmax - A Decentralized, Secure and Anonymous
                              Platform.
                            </p>
                            <ul className="nftmax-userprofile__meta">
                              <li className="nftmax-userprofile__meta">
                                <a href="#">
                                  <span className="nftmax-userprofile__number ">
                                    $ {dbuser !== null ? dbuser.t_staking : "0"}
                                  </span>{" "}
                                  <br />
                                  Total Staking
                                </a>
                              </li>
                              <li className="nftmax-userprofile__meta">
                                <a href="#">
                                  <span className="nftmax-userprofile__number">
                                    ${" "}
                                    {dbuser !== null
                                      ? Number(
                                          dbuser.t_cold_pocket +
                                            dbuser.t_other_pocket
                                        ).toFixed(2)
                                      : "0"}
                                  </span>
                                  <br />
                                  Earning
                                </a>
                              </li>
                              <li className="nftmax-userprofile__meta">
                                <a href="#">
                                  <span className="nftmax-userprofile__number">
                                    0
                                  </span>
                                  <br />
                                  Time
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="nftmax-userprofile__right">
                          <Link
                            to="/edit-profile"
                            className="nftmax-btn nftmax-btn__primary nftmax-btn__profile radius"
                          >
                            Edit Profile
                          </Link>
                          <a
                            className="nftmax-btn nftmax-btn__share radius"
                            data-bs-toggle="modal"
                            data-bs-target="#followers_modal"
                          >
                            <svg
                              width={23}
                              height={22}
                              viewBox="0 0 23 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12.4922 5.51801C12.4922 5.43549 12.4922 5.37668 12.4922 5.31786C12.4908 3.80698 12.4894 2.2961 12.4881 0.784769C12.4876 0.460163 12.6709 0.18525 12.97 0.0626106C13.2823 -0.0654996 13.6374 0.00562209 13.8708 0.251813C14.1079 0.50165 14.3391 0.756959 14.5725 1.00999C16.9742 3.6114 19.3723 6.216 21.7827 8.80921C22.0863 9.13564 22.0863 9.7347 21.7863 10.0579C19.1471 12.9024 16.5188 15.7568 13.8868 18.608C13.6356 18.8802 13.3069 18.9586 12.9864 18.8264C12.6668 18.6947 12.4899 18.4006 12.4899 18.0404C12.4899 16.5204 12.4844 15 12.4803 13.48C12.4803 13.449 12.4735 13.418 12.4676 13.3706C12.4092 13.3706 12.3559 13.3697 12.3021 13.3706C9.96553 13.4216 7.82231 14.1082 5.8537 15.3506C4.32002 16.3185 3.04895 17.5544 2.11206 19.1177C1.60053 19.9712 1.23261 20.8826 1.02517 21.8573C1.01788 21.8915 1.00602 21.9248 0.984596 22C0.896606 21.7821 0.818646 21.6024 0.750715 21.4196C0.0700445 19.5905 -0.16338 17.7081 0.114724 15.7728C0.385533 13.8885 1.11088 12.1857 2.25521 10.6707C4.17459 8.12899 6.70944 6.52693 9.80642 5.8107C10.6271 5.62104 11.4623 5.52804 12.3053 5.51846C12.3591 5.51755 12.4133 5.51801 12.4922 5.51801Z"
                                fill="#00c5ff"
                              />
                            </svg>
                          </a>
                          <div
                            className="nftmax-preview__modal modal fade"
                            id="followers_modal"
                            tabIndex={-1}
                            aria-labelledby="previewModalLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog  nftmax-followers__modal">
                              <div className="modal-content nftmax-preview__modal-content">
                                <div className="modal-header nftmax__modal__header">
                                  <h4
                                    className="modal-title nftmax-preview__modal-title"
                                    id="previewModalLabel"
                                  >
                                    Share
                                  </h4>
                                  <button
                                    type="button"
                                    className="nftmax-preview__modal--close btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <svg
                                      width={36}
                                      height={36}
                                      viewBox="0 0 36 36"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M36 16.16C36 17.4399 36 18.7199 36 20.0001C35.7911 20.0709 35.8636 20.2554 35.8385 20.4001C34.5321 27.9453 30.246 32.9248 22.9603 35.2822C21.9006 35.6251 20.7753 35.7657 19.6802 35.9997C18.4003 35.9997 17.1204 35.9997 15.8401 35.9997C15.5896 35.7086 15.2189 35.7732 14.9034 35.7093C7.77231 34.2621 3.08728 30.0725 0.769671 23.187C0.435002 22.1926 0.445997 21.1199 0 20.1599C0 18.7198 0 17.2798 0 15.8398C0.291376 15.6195 0.214408 15.2656 0.270759 14.9808C1.71321 7.69774 6.02611 2.99691 13.0428 0.700951C14.0118 0.383805 15.0509 0.386897 15.9999 0C17.2265 0 18.4532 0 19.6799 0C19.7156 0.124041 19.8125 0.136067 19.9225 0.146719C27.3 0.868973 33.5322 6.21922 35.3801 13.427C35.6121 14.3313 35.7945 15.2484 36 16.16ZM33.011 18.0787C33.0433 9.77105 26.3423 3.00309 18.077 2.9945C9.78479 2.98626 3.00344 9.658 2.98523 17.8426C2.96667 26.1633 9.58859 32.9601 17.7602 33.0079C26.197 33.0577 32.9787 26.4186 33.011 18.0787Z"
                                        fill="#00c5ff"
                                        fillOpacity="0.6"
                                      ></path>
                                      <path
                                        d="M15.9309 18.023C13.9329 16.037 12.007 14.1207 10.0787 12.2072C9.60071 11.733 9.26398 11.2162 9.51996 10.506C9.945 9.32677 11.1954 9.0811 12.1437 10.0174C13.9067 11.7585 15.6766 13.494 17.385 15.2879C17.9108 15.8401 18.1633 15.7487 18.6375 15.258C20.3586 13.4761 22.1199 11.7327 23.8822 9.99096C24.8175 9.06632 26.1095 9.33639 26.4967 10.517C26.7286 11.2241 26.3919 11.7413 25.9133 12.2178C24.1757 13.9472 22.4477 15.6855 20.7104 17.4148C20.5228 17.6018 20.2964 17.7495 20.0466 17.9485C22.0831 19.974 24.0372 21.8992 25.9689 23.8468C26.9262 24.8119 26.6489 26.1101 25.4336 26.4987C24.712 26.7292 24.2131 26.3441 23.7455 25.8757C21.9945 24.1227 20.2232 22.3892 18.5045 20.6049C18.0698 20.1534 17.8716 20.2269 17.4802 20.6282C15.732 22.4215 13.9493 24.1807 12.1777 25.951C11.7022 26.4262 11.193 26.7471 10.4738 26.4537C9.31345 25.9798 9.06881 24.8398 9.98589 23.8952C11.285 22.5576 12.6138 21.2484 13.9387 19.9355C14.5792 19.3005 15.2399 18.6852 15.9309 18.023Z"
                                        fill="#00c5ff"
                                        fillOpacity="0.6"
                                      ></path>
                                    </svg>
                                  </button>
                                </div>
                                <div className="modal-body nftmax-modal__body">
                                  <ul className="followers-list">
                                    <li className="followers-list__single">
                                      <div className="followers-list__first">
                                        <div className="followers-list__follower">
                                          <img
                                            src="assets/img/follower.png"
                                            alt="#"
                                          />
                                          <div className="followers-list__badge">
                                            <img
                                              src="assets/img/badge.png"
                                              alt="#"
                                            />
                                          </div>
                                        </div>
                                        <div className="followers-list__content">
                                          <h4 className="followers-list__title">
                                            Whatsapp
                                          </h4>
                                          <p
                                            href="#"
                                            className="followers-list__text"
                                          >
                                            share now
                                          </p>
                                        </div>
                                      </div>
                                      <div className="followers-list__button">
                                        <a
                                          href="#"
                                          className="nftmax-btn nftmax-btn__bordered nftmax-btn__followed radius btn btn-primary btn-lg disabled"
                                          tabIndex={-1}
                                          role="button"
                                          aria-disabled="true"
                                        >
                                          <span className="ntfmax__btn-textgr">
                                            Share
                                          </span>
                                        </a>
                                      </div>
                                    </li>
                                    
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
