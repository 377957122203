import React, { useContext, useEffect, useState } from "react";
import Header from "./Comman/Header";
import Sidebar from "./Comman/Sidebar";
import { OnmaxContext } from "../OnmaxContext";
import axios from "axios";
import WithdrawalDone from "./WithdrawalDone";

export default function Withdrawal() {
  const {
    rate,
    copyaddress,
    toastSuccess,
    toastError,
    tokenrate,
    account,
    dbuser,
    smuser,
    getuserDB,
  } = useContext(OnmaxContext);
  const withdrawFee = 10; //%
  const [amt, setamt] = useState(0);
  const [amt2, setamt2] = useState(0);
  const [token, settoken] = useState(0);
  const [token2, settoken2] = useState(0);
  const [tokenRcv, settokenRcv] = useState(0);
  const [token2Rcv, settoken2Rcv] = useState(0);
  const [tokenFee, settokenFee] = useState(0);
  const [token2Fee, settoken2Fee] = useState(0);
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);

  const withdraw = async (type) => {
    if (!account) {
      toastError("Connect first");
      return;
    }
    console.log("!dbuser",dbuser);
    if (!dbuser) {
      return;
    }

    if (type === 1) {
      if (!amt) {
        toastError("Enter amount to withdraw");
        return;
      }
      if (amt > dbuser.t_cold_pocket_balance) {
        toastError("Insufficient Balance");
        return;
      }
      if (amt < 10) {
        toastError("Minimum withdrawal is $10 ");
        return;
      }
    } else {
      if (!amt2) {
        toastError("Enter amount to withdraw");
        return;
      }
      if (amt2 > dbuser.t_other_pocket_balance) {
        toastError("Insufficient Balance");
        return;
      }
      if (amt2 < 15) {
        toastError("Minimum withdrawal is $15 ");
        return;
      }
    }
    if(!dbuser.status){
      toastError("Your account is not active");
      return;
    }
    if(dbuser.isBlocked){
      toastError("Your account is blocked");
      return;
    }
    if(dbuser.restake){
      toastError("Please restake your account");
      return;
    }
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "withdrawal",
        submethod: "insert",
        key: process.env.REACT_APP_KEY,
        address: account,
        amount: type === 1 ? amt : amt2,
        token: type === 1 ? token : token2,
        type: type, //1.cold , 2.mining
        rate: tokenrate,
        fee: type === 1 ? tokenFee : token2Fee,
        t_rcv: type === 1 ? tokenRcv : token2Rcv,
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        getDataPending();
        getuserDB();
        if (res.data.error) {
          toastError(res.data.error);
          return "";
        }

        toastSuccess(res.data.data);
      });
  };
  const getDataPending = async () => {
    if (!account) {
      return;
    }
    setisLoading(true);

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "withdrawal",
        submethod: "getpbyid",
        address: account,
        key: process.env.REACT_APP_KEY,
        page: currentPage,
        pageSize: pageSize,
      })
      .then((res) => {
        setisLoading(false);

        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdata(res.data.data);
        setPages(Math.ceil(res.data.dataLength / pageSize));
      });
  };
  useEffect(() => {
    getDataPending();
  }, [account]);
  useEffect(() => {
    var tmt = amt / tokenrate;
    var tmtfee = (tmt * 10) / 100;
    settoken(tmt);
    settokenFee(tmtfee);
    settokenRcv(tmt - tmtfee);
  }, [amt, tokenrate]);

  useEffect(() => {
    var tmt = amt2 / tokenrate;
    var tmtfee = (tmt * 10) / 100;
    settoken2(tmt);
    settoken2Fee(tmtfee);
    settoken2Rcv(tmt - tmtfee);
  }, [amt2, tokenrate]);

  return (
    <>
      <div className="body-bg">
        <Sidebar />
        <Header />
        <section className="nftmax-adashboard nftmax-show">
          <div className="container">
            <div className="row align-items-end justify-content-center">
             
              <div className="col-lg-12 col-md-12 col-12 nftmax-main__colum">
                <div className="nftmax-body">
                <div className="nftmax-inner__heading">
                    <h2 className="nftmax-inner__page-title">Withdrawal</h2>
                  </div>
                  <div className="nftmax-dsinner">
                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="nftmax-personals">
                          <div className="nftmax-ptab">
                            <div className="nftmax-ptabs__inner mt-4">
                              <div className="tab-content" id="nav-tabContent">
                                <div
                                  className="tab-pane fade show active"
                                  id="id1"
                                  role="tabpanel"
                                >
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="nftmax-ptabs__separa">
                                        <div className="nftmax-ptabs__form-main">
                                          <div className="nftmax__item-form--group">
                                            <div className="d-flex justify-content-between">
                                              <label className="nftmax__item-label">
                                                Cold Pocket
                                              </label>
                                              <label className="nftmax__item-label">
                                                ${" "}
                                                {dbuser !== null
                                                  ? dbuser.t_cold_pocket_balance
                                                  : "0"}
                                              </label>
                                            </div>
                                            <input
                                              className="nftmax__item-input"
                                              type="text"
                                              placeholder="Min $10"
                                              onChange={(e) =>
                                                setamt(e.target.value)
                                              }
                                            />
                                          </div>
                                          <span>
                                            Total token: {token}{" "}
                                            {process.env.REACT_APP_TICKER}
                                          </span>
                                          <br />
                                          <span>
                                            Fee: {tokenFee}{" "}
                                            {process.env.REACT_APP_TICKER}
                                          </span>
                                          <br />
                                          <span>
                                            Total Recieve: {tokenRcv}{" "}
                                            {process.env.REACT_APP_TICKER}
                                          </span>
                                          <br />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="nftmax__item-button--group nftmax__ptabs-bottom">
                                    <button
                                      className="nftmax__item-button--single nftmax-btn nftmax-btn__bordered bg radius "
                                      type="button"
                                      onClick={() => withdraw(1)}
                                    >
                                      Withdraw now
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="nftmax-personals">
                          <div className="nftmax-ptab">
                            <div className="nftmax-ptabs__inner mt-4">
                              <div className="tab-content" id="nav-tabContent">
                                <div
                                  className="tab-pane fade show active"
                                  id="id1"
                                  role="tabpanel"
                                >
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="nftmax-ptabs__separa">
                                        <div className="nftmax-ptabs__form-main">
                                          <div className="nftmax__item-form--group">
                                          <div className="d-flex justify-content-between">
                                              <label className="nftmax__item-label">
                                                Mining Pocket
                                              </label>
                                              <label className="nftmax__item-label">
                                                ${" "}
                                                {dbuser !== null
                                                  ? dbuser.t_other_pocket_balance
                                                  : "0"}
                                              </label>
                                            </div>
                                            <input
                                              className="nftmax__item-input"
                                              type="text"
                                              placeholder="Min $15"
                                              onChange={(e) =>
                                                setamt2(e.target.value)
                                              }
                                            />
                                          </div>
                                          <span>
                                            Total token: {token2}{" "}
                                            {process.env.REACT_APP_TICKER}
                                          </span>
                                          <br />
                                          <span>
                                            Fee: {token2Fee}{" "}
                                            {process.env.REACT_APP_TICKER}
                                          </span>
                                          <br />
                                          <span>
                                            Total Recieve: {token2Rcv}{" "}
                                            {process.env.REACT_APP_TICKER}
                                          </span>
                                          <br />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="nftmax__item-button--group nftmax__ptabs-bottom">
                                    <button
                                      className="nftmax__item-button--single nftmax-btn nftmax-btn__bordered bg radius "
                                      type="button"
                                      onClick={() => withdraw(2)}
                                    >
                                      Withdraw now
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-12 col-12 nftmax-main__colum">
                <div className="nftmax-body">
                  <div className="nftmax-dsinner">
                    <div className="nftmax-table mg-top-40">
                      <div className="nftmax-table__heading">
                        <h3 className="nftmax-table__title mb-0">
                          Pending Withdrawal
                        </h3>
                      </div>
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="table_1"
                          role="tabpanel"
                          aria-labelledby="table_1"
                        >
                          <table
                            id="nftmax-table__main"
                            className="nftmax-table__main nftmax-table__main-v1"
                          >
                            <thead className="nftmax-table__head">
                              <tr>
                                <th className="nftmax-table__column-1 nftmax-table__h1">
                                  Amount
                                </th>
                                <th className="nftmax-table__column-2 nftmax-table__h2">
                                  Token
                                </th>
                                <th className="nftmax-table__column-3 nftmax-table__h3">
                                  Fee
                                </th>
                                <th className="nftmax-table__column-4 nftmax-table__h4">
                                  Total Recieve
                                </th>
                                <th className="nftmax-table__column-5 nftmax-table__h5">
                                  Rate
                                </th>
                                <th className="nftmax-table__column-6 nftmax-table__h6">
                                  Type
                                </th>
                                <th className="nftmax-table__column-7 nftmax-table__h7">
                                  Datetime
                                </th>
                              </tr>
                            </thead>
                            {isLoading ? "Data is loading" : ""}
                            {!isLoading ? (
                              !data.length ? (
                                <tr className="text-center">
                                  <td className="text-center" colSpan={7}>
                                    <span className="w-100">No data found</span>
                                  </td>
                                </tr>
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}
                            <tbody className="nftmax-table__body">
                              {data.map((e, i) => {
                                return (
                                  <tr>
                                    <td className="nftmax-table__column-1 nftmax-table__data-1">
                                      <div className="nftmax-table__product">
                                        <div className="nftmax-table__product-content">
                                          <h4 className="nftmax-table__product-title">
                                            ${e.amount}
                                          </h4>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="nftmax-table__column-2 nftmax-table__data-2">
                                      <div className="nftmax-table__amount nftmax-table__text-one">
                                        <span className="nftmax-table__text">
                                          {e.token}{" "}
                                          {process.env.REACT_APP_TICKER}
                                        </span>
                                      </div>
                                    </td>
                                    <td className="nftmax-table__column-3 nftmax-table__data-3">
                                      <div className="nftmax-table__amount nftmax-table__text-two">
                                        <span className="nftmax-table__text">
                                          {e.fee} {process.env.REACT_APP_TICKER}
                                        </span>
                                      </div>
                                    </td>
                                    <td className="nftmax-table__column-4 nftmax-table__data-4">
                                      <span className="nftmax-table__text">
                                        {e.t_rcv} {process.env.REACT_APP_TICKER}
                                      </span>
                                    </td>
                                    <td className="nftmax-table__column-5 nftmax-table__data-5">
                                      <p className="nftmax-table__text nftmax-table__bid-text">
                                        ${e.rate}
                                      </p>
                                    </td>
                                    <td className="nftmax-table__column-6 nftmax-table__data-6">
                                      <p className="nftmax-table__text nftmax-table__time">
                                        {e.type === 1
                                          ? "Cold Withdrawal"
                                          : "Mining Withdrawal"}
                                      </p>
                                    </td>
                                    <td className="nftmax-table__column-7 nftmax-table__data-7">
                                      <span className="nftmax-table__text">
                                        {e.datetime}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <WithdrawalDone/>
          </div>
        </section>
      </div>
    </>
  );
}
