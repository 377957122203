import React, { useContext, useEffect, useState } from "react";
import Sidebar from "./Comman/Sidebar";
import Header from "./Comman/Header";
import { OnmaxContext } from "../OnmaxContext";
import axios from "axios";

export default function Refferal() {
  const { account,copyaddress,formatAddress} = useContext(OnmaxContext);
  const [data, setdata] = useState([]);
  const [level, setlevel] = useState(1);
  const [isLoading, setisLoading] = useState(false);
  const getData = async () => {
    if (!account) {
      return;
    }
    setisLoading(true);
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "user",
        submethod: "level",
        address: account,
        key: process.env.REACT_APP_KEY,
        level: level,
        page: 1,
        pageSize: 100,
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        setisLoading(false);
        if (res.data.error) {
          return "";
        }
        setdata(res.data.data);
      });
  };
  useEffect(() => {
    getData();
  }, [account]);
  return (
    <>
      <div className="body-bg">
        <Sidebar />
        <Header />
        <section className="nftmax-adashboard nftmax-show">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12 col-12 nftmax-main__colum">
                <div className="nftmax-body">
                  <div className="nftmax-dsinner">
                    <div className="nftmax-table mg-top-40">
                      <div className="nftmax-table__heading">
                        <h3 className="nftmax-table__title mb-0">Team</h3>
                        <ul
                          className="nav nav-tabs  nftmax-dropdown__list"
                          id="nav-tab"
                          role="tablist"
                        >
                          <li className="nav-item dropdown">
                            <div class="nftmax-header__form nftmax-chatbox__search d-flex flex-lg-nowrap flex-sm-wrap flex-wrap">
                              <div
                                className="nftmax-header__form-inner"
                                action="#"
                              >
                                <input
                                  type="number"
                                  placeholder="Enter Level to search"
                                  value={level}
                                  onChange={(e) => setlevel(e.target.value)}
                                />
                              </div>
                              <button
                                class="nftmax__item-button--single nftmax-btn nftmax-btn__bordered bg radius "
                                type="submit"
                                onClick={() => getData()}
                              >
                                Search
                              </button>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="table_1"
                          role="tabpanel"
                          aria-labelledby="table_1"
                        >
                          <table
                            id="nftmax-table__main"
                            className="nftmax-table__main nftmax-table__main-v1"
                          >
                            <thead className="nftmax-table__head">
                              <tr>
                                <th className="nftmax-table__column-1 nftmax-table__h1">
                                  Sr.
                                </th>
                                <th className="nftmax-table__column-2 nftmax-table__h2">
                                  Address
                                </th>
                                <th className="nftmax-table__column-3 nftmax-table__h4">
                                  Validator
                                </th>
                                <th className="nftmax-table__column-4 nftmax-table__h4">
                                  Team Business 
                                </th>
                                <th className="nftmax-table__column-5 nftmax-table__h5">
                                  Staking
                                </th>
                                <th className="nftmax-table__column-6 nftmax-table__h6">
                                  Datetime
                                </th>
                                <th className="nftmax-table__column-7 nftmax-table__h7">
                                  Status
                                </th>
                              </tr>
                            </thead>
                            {isLoading ? "Data is loading" : ""}
                            {!isLoading ? (
                              !data.length ? (
                                <tr className="text-center">
                                  <td className="text-center" colSpan={6}>
                                    <span className="w-100">No data found</span>
                                  </td>
                                </tr>
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}
                            <tbody className="nftmax-table__body">
                              {data.map((ele, i) => {
                                return (
                                  <tr>
                                    <td className="nftmax-table__column-1 nftmax-table__data-1">
                                      <div className="nftmax-table__text">
                                        {i + 1}
                                      </div>
                                    </td>
                                    <td className="nftmax-table__column-2 nftmax-table__data-3">
                                      <div className="nftmax-table__amount nftmax-table__text-two">
                                        <span className="nftmax-table__text" onClick={() =>
                                        copyaddress(ele.address)
                                      }>
                                          {formatAddress(ele.address)}  <i className="fa fa-copy"></i>
                                        </span>
                                      </div>
                                    </td>
                                    <td className="nftmax-table__column-3 nftmax-table__data-4">
                                      <span className="nftmax-table__text" onClick={() =>
                                        copyaddress(ele.ref_address)
                                      }>
                                        {formatAddress(ele.ref_address)}  <i className="fa fa-copy"></i>
                                      </span>
                                    </td>
                                    <td className="nftmax-table__column-4 nftmax-table__data-5">
                                      <p className="nftmax-table__text nftmax-table__bid-text">
                                        $ {ele.team_business} ({ele.team})
                                      </p>
                                    </td>
                                    <td className="nftmax-table__column-5 nftmax-table__data-5">
                                      <p className="nftmax-table__text nftmax-table__bid-text">
                                        $ {ele.t_staking}
                                      </p>
                                    </td>
                                    <td className="nftmax-table__column-6 nftmax-table__data-6">
                                      <p className="nftmax-table__text nftmax-table__time">
                                        {ele.createdAt}
                                      </p>
                                    </td>
                                    <td className="nftmax-table__column-7 nftmax-table__data-8">
                                      {ele.status ? (
                                        <div className="nftmax-table__status nftmax-sbcolor">
                                          Active
                                        </div>
                                      ) : (
                                        <div className="nftmax-table__status nftmax-rbcolor">
                                          Not Active
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
