import React from 'react'
import { Link } from 'react-router-dom'

export default function CommingSoon() {
    return (
        <div className="body-bg">
            <header className="nftmax-header px-0">
                <div className="container">
                    <div className="row g-50">
                        <div className="col-12">
                            <div className="nftmax-header__inner">
                                <img src="assets/img/logo-white.png" alt="#" />
                                <div className="nftmax__sicon close-icon d-xl-none"><img src="assets/img/menu-toggle.svg" alt="#" />
                                </div>
                                <div className="nftmax-header__left">
                                    <div className="nftmax-header__form d-none">
                                        <form className="nftmax-header__form-inner" action="#">
                                            <button className="search-btn" type="submit"><img src="assets/img/search.png" alt="#" /></button>
                                            <input name="s" defaultValue type="text" placeholder="Search items, collections..." />
                                        </form>
                                    </div>
                                </div>
                                <div className="nftmax-header__right">
                                    <div className="nftmax-header__group">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <section className="">
                <div className="container">
                    <div className="row justify-content-center align-content-center">
                        <div className="col-xxl-8 col-12 nftmax-main__colum">
                            <div className="nftmax-body">
                                <div className="nftmax-dsinner">
                                    <div className="nftmax-wallet__dashboard">
                                        <div className="row nftmax-gap-30 justify-content-center">
                                            <div className="col-lg-10 col-md-12 col-12">
                                                <div className=" mg-top-40">
                                                    <div className="nftmax-history__main">
                                                        <div className="trending-action">
                                                            <div className="row justify-content-center">
                                                                <div className="col-8">
                                                                    <div className="tab-content" id="nav-tabContent">
                                                                        <div className="tab-pane fade show active" id="id1" role="tabpanel" aria-labelledby="nav-home-tab">
                                                                            <div className="row nftmax-gap-sq30">
                                                                                <div className="col-lg-6 col-md-6 col-12">
                                                                                    <div className="trending-action__single trending-action__single--v2">
                                                                                        <div className="trending-action__head">
                                                                                            <img src="assets/img/marketplace-1.png" alt="#" />
                                                                                        </div>
                                                                                        <div className="trending-action__body trending-marketplace__body">
                                                                                            <div className="nftmax-currency">
                                                                                                <div className="nftmax-currency__main">
                                                                                                    <div className="nftmax-currency__content">
                                                                                                        <h4 className="nftmax-currency__content-title">Affiliate </h4>
                                                                                                        {/* <p className="nftmax-currency__content-sub">(773.69 USD)</p> */}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <Link to='/' className="nftmax-btn nftmax-btn__secondary radius">Affiliate</Link>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6 col-md-6 col-12">

                                                                                    <div className="trending-action__single trending-action__single--v2">
                                                                                        <div className="trending-action__head">
                                                                                            <img src="assets/img/marketplace-2.png" alt="#" />
                                                                                        </div>
                                                                                        <div className="trending-action__body trending-marketplace__body">
                                                                                            <div className="nftmax-currency">
                                                                                                <div className="nftmax-currency__main">
                                                                                                    <div className="nftmax-currency__content">
                                                                                                        <h4 className="nftmax-currency__content-title">Stake</h4>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <Link className="nftmax-btn nftmax-btn__secondary radius">
                                                                                                    Connect Wallet</Link>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6 col-md-6 col-12">
                                                                                    <div className="trending-action__single trending-action__single--v2">
                                                                                        <div className="trending-action__head">
                                                                                            <img src="assets/img/marketplace-3.png" alt="#" />
                                                                                        </div>
                                                                                        <div className="trending-action__body trending-marketplace__body">
                                                                                            <div className="nftmax-currency">
                                                                                                <div className="nftmax-currency__main">
                                                                                                    <div className="nftmax-currency__content">
                                                                                                        <h4 className="nftmax-currency__content-title">Help</h4>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <Link className="nftmax-btn nftmax-btn__secondary radius"><i className='fa fa-arrow-right'></i></Link>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6 col-md-6 col-12">
                                                                                    <div className="trending-action__single trending-action__single--v2">
                                                                                        <div className="trending-action__head">
                                                                                            <img src="assets/img/marketplace-4.png" alt="#" />
                                                                                        </div>
                                                                                        <div className="trending-action__body trending-marketplace__body">
                                                                                            <div className="nftmax-currency">
                                                                                                <div className="nftmax-currency__main">
                                                                                                    <div className="nftmax-currency__content">
                                                                                                        <h4 className="nftmax-currency__content-title">Whitepaper</h4>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <Link className="nftmax-btn nftmax-btn__secondary radius"><i className='fa fa-arrow-right'></i></Link>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
