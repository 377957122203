import React, { useContext, useEffect, useState } from "react";
import Sidebar from "./Comman/Sidebar";
import Header from "./Comman/Header";
import { OnmaxContext } from "../OnmaxContext";
import axios from "axios";

export default function Unstake() {
  const { account, settings,formatAddress,copyaddress } = useContext(OnmaxContext);
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);

  const getData = async () => {
    if (!account) {
      return;
    }
    setisLoading(true);

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "unstake",
        submethod: "getbyid",
        address: account,
        key: process.env.REACT_APP_KEY,
        page: currentPage,
        pageSize: pageSize,
      })
      .then((res) => {
        // console.log("ress", res.data);
        // var dedata = decryptData(res.data);
        setisLoading(false);

        if (res.data.error) {
          return "";
        }
        setdata(res.data.data);
        setPages(Math.ceil(res.data.dataLength / pageSize));
      });
  };
  useEffect(() => {
    getData();
  }, [account, pages, pageSize, currentPage]);
  return (
    <>
      <div className="body-bg">
        <Sidebar />
        <Header />
        <section className="nftmax-adashboard nftmax-show">
          <div className="container">
            <div className="row align-items-end justify-content-center">
              <div className="col-lg-12 col-md-12 col-12 nftmax-main__colum">
                <div className="nftmax-body">
                  <div className="nftmax-inner__heading ">
                    <h2 className="nftmax-inner__page-title">Unstake</h2>
                  </div>
                  <div className="nftmax-dsinner">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="nftmax-personals">
                          <div className="nftmax-ptab">
                            <div className="nftmax-ptabs__inner mt-4">
                              <div className="tab-content" id="nav-tabContent">
                                <div
                                  className="tab-pane fade show active"
                                  id="id1"
                                  role="tabpanel"
                                >
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="nftmax-ptabs__separa">
                                        <div className="nftmax-ptabs__form-main">
                                          <div className="nftmax__item-form--group d-flex justify-content-center">
                                            <a
                                              href={`${process.env.REACT_APP_EXPLORER}address/${settings.contract}#writeContract#F1`}
                                              target="_blank"
                                              rel="noreferrer"
                                              className="nftmax__item-button--single nftmax-btn nftmax-btn__bordered bg radius "
                                              type="button"
                                            >
                                              Unstake now
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-12 col-12 nftmax-main__colum">
                <div className="nftmax-body">
                  <div className="nftmax-dsinner">
                    <div className="nftmax-table mg-top-40">
                      <div className="nftmax-table__heading">
                        <h3 className="nftmax-table__title mb-0">
                          Unstake Details
                        </h3>
                      </div>
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="table_1"
                          role="tabpanel"
                          aria-labelledby="table_1"
                        >
                          <table
                            id="nftmax-table__main"
                            className="nftmax-table__main nftmax-table__main-v1"
                          >
                            <thead className="nftmax-table__head">
                              <tr>
                                <th className="nftmax-table__column-1 nftmax-table__h1">
                                  Sr
                                </th>
                                <th className="nftmax-table__column-2 nftmax-table__h2">
                                  Hash
                                </th>
                                <th className="nftmax-table__column-3 nftmax-table__h2">
                                  Amount
                                </th>
                                <th className="nftmax-table__column-4 nftmax-table__h2">
                                  Tokens
                                </th>
                                <th className="nftmax-table__column-5 nftmax-table__h5">
                                  Rate
                                </th>
                                <th className="nftmax-table__column-6 nftmax-table__h6">
                                Unstake Date
                                </th>
                                <th className="nftmax-table__column-6 nftmax-table__h6">
                                Release Date
                                </th>
                                <th className="nftmax-table__column-7 nftmax-table__h6">
                                Status
                                </th>
                                
                              </tr>
                            </thead>
                            {isLoading ? "Data is loading" : ""}
                            {!isLoading ? (
                              !data.length ? (
                                <tr className="text-center">
                                  <td className="text-center" colSpan={7}>
                                    <span className="w-100">No data found</span>
                                  </td>
                                </tr>
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}
                            <tbody className="nftmax-table__body">
                              {data.map((ele, i) => {
                                return (
                                  <tr>
                                    <td className="nftmax-table__column-1 nftmax-table__data-1">
                                      <div className="nftmax-table__text">
                                        {ele.id}
                                      </div>
                                    </td>
                                    <td className="nftmax-table__column-2 nftmax-table__data-3">
                                      <div className="nftmax-table__amount nftmax-table__text-two">
                                      {ele.unstake_hash ? 
                                      <span className="nftmax-table__text" onClick={() =>
                                        copyaddress(ele.unstake_hash)}>
                                          {formatAddress(ele.unstake_hash)} <i className="fa fa-copy"></i>
                                        </span>
                                        :''}
                                      </div>
                                    </td>
                                    <td className="nftmax-table__column-3 nftmax-table__data-3">
                                      <div className="nftmax-table__amount nftmax-table__text-two">
                                        <span className="nftmax-table__text">
                                          $ {ele.amount}
                                        </span>
                                      </div>
                                    </td>
                                    <td className="nftmax-table__column-4 nftmax-table__data-4">
                                      <span className="nftmax-table__text">
                                        {ele.tokens} {process.env.REACT_APP_TICKER}
                                      </span>
                                    </td>
                                    <td className="nftmax-table__column-5 nftmax-table__data-4">
                                      <span className="nftmax-table__text">
                                        $ {ele.rate}
                                      </span>
                                    </td>
                                    <td className="nftmax-table__column-6 nftmax-table__data-5">
                                      <p className="nftmax-table__text nftmax-table__bid-text">
                                      {ele.createdAt}
                                      </p>
                                    </td>
                                    <td className="nftmax-table__column-6 nftmax-table__data-5">
                                      <p className="nftmax-table__text nftmax-table__bid-text">
                                      {ele.unstake_date}
                                      </p>
                                    </td>
                                    <td className="nftmax-table__column-7 nftmax-table__data-5">
                                      {ele.status? (
                                        <div className="nftmax-table__status nftmax-sbcolor">
                                          Success
                                        </div>
                                      ) : (
                                        <div className="nftmax-table__status nftmax-rbcolor">
                                          Pending
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
