import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function Sidebar() {
  var navigate = useNavigate();
  const currentPath = window.location.pathname;
  const [show, setShow] = useState(false);
    const handleClickShow = () => {
        setShow(true);
    }

  useEffect(() => {
    var iscon = localStorage.getItem("isconnected");
    if (iscon !== "true") {
      navigate("/");
    }
  }, []);
  return (
    <>
      {/* <div className={show ? "nftmax-smenu nftmax-close" : "nftmax-smenu"}>
        <div className="admin-menu">
          <div className="logo">
            <Link to="/">
              <img
                className="nftmax-logo__main"
                src="assets/img/logo-white.png"
                alt="#"
              />
            </Link>
            <div className="nftmax__sicon close-icon" >
              <img src="assets/img/menu-toggle.svg" alt="#" onClick={handleClickShow} />
            </div>
          </div>
          <div className="admin-menu__one">
            <h4 className="admin-menu__title nftmax-scolor">Menu</h4>
            <div className="menu-bar">
              <ul className="menu-bar__one">
                <li
                  className={`${currentPath === "/dashboard" ? "active" : ""}`}
                >
                  <Link to="/dashboard">
                    <span className="menu-bar__text">
                      <span className="nftmax-menu-icon nftmax-svg-icon__v1">
                        <svg
                          className="nftmax-svg-icon"
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          viewBox="0 0 16 16"
                        >
                          <path d="M0.800781 2.60005V7.40005H7.40078V0.800049H2.60078C2.12339 0.800049 1.66555 0.989691 1.32799 1.32726C0.990424 1.66482 0.800781 2.12266 0.800781 2.60005H0.800781Z"></path>
                          <path d="M13.4016 0.800049H8.60156V7.40005H15.2016V2.60005C15.2016 2.12266 15.0119 1.66482 14.6744 1.32726C14.3368 0.989691 13.879 0.800049 13.4016 0.800049V0.800049Z"></path>
                          <path d="M0.800781 13.4001C0.800781 13.8775 0.990424 14.3353 1.32799 14.6729C1.66555 15.0105 2.12339 15.2001 2.60078 15.2001H7.40078V8.6001H0.800781V13.4001Z"></path>
                          <path d="M8.60156 15.2001H13.4016C13.879 15.2001 14.3368 15.0105 14.6744 14.6729C15.0119 14.3353 15.2016 13.8775 15.2016 13.4001V8.6001H8.60156V15.2001Z"></path>
                        </svg>
                      </span>
                      <span className="menu-bar__name">Dashboard</span>
                    </span>
                  </Link>
                </li>
                <li className={`${currentPath === "/staking" ? "active" : ""}`}>
                  <Link to="/staking">
                    <span className="menu-bar__text">
                      <span className="nftmax-menu-icon nftmax-svg-icon__v2">
                        <svg
                          className="nftmax-svg-icon"
                          viewBox="0 0 16 16"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M8.40093 8.39892C8.07534 8.74593 7.8023 9.13874 7.59052 9.56484L13.3422 15.3165C13.4732 15.4475 13.651 15.5211 13.8362 15.5211C14.0215 15.5211 14.1992 15.4475 14.3303 15.3165L15.3185 14.3283C15.3834 14.2634 15.4349 14.1864 15.47 14.1016C15.5052 14.0168 15.5232 13.9259 15.5232 13.8342C15.5232 13.7424 15.5052 13.6515 15.47 13.5667C15.4349 13.482 15.3834 13.4049 15.3185 13.34L9.56685 7.5899C9.13999 7.79984 8.74699 8.07254 8.40093 8.39892Z"></path>
                          <path d="M4.94216 4.94003C4.41373 5.43043 3.80202 5.8226 3.1358 6.09809L6.74747 9.70976C7.02543 9.04469 7.41799 8.43359 7.90728 7.90427C8.43576 7.4139 9.04746 7.02168 9.71363 6.74604L6.10179 3.13507C5.82373 3.79989 5.43125 4.41079 4.94216 4.94003Z"></path>
                          <path d="M7.4116 0.494075L6.42351 1.48216C6.15066 1.75501 6.15066 2.19739 6.42351 2.47024L10.3758 6.42256C10.6487 6.69541 11.0911 6.69541 11.3639 6.42256L12.352 5.43448C12.6249 5.16163 12.6249 4.71925 12.352 4.4464L8.39968 0.494075C8.12683 0.221224 7.68445 0.221224 7.4116 0.494075Z"></path>
                          <path d="M1.48209 6.42236L0.49401 7.41044C0.221159 7.68329 0.221159 8.12567 0.49401 8.39852L4.44633 12.3508C4.71919 12.6237 5.16156 12.6237 5.43441 12.3508L6.4225 11.3628C6.69535 11.0899 6.69535 10.6475 6.4225 10.3747L2.47017 6.42236C2.19732 6.14951 1.75494 6.14951 1.48209 6.42236Z"></path>
                        </svg>
                      </span>
                      <span className="menu-bar__name">Staking</span>
                    </span>
                  </Link>
                </li>
                <li
                  className={`${currentPath === "/refferal" ? "active" : ""}`}
                >
                  <Link to="/refferal">
                    <span className="menu-bar__text">
                      <span className="nftmax-menu-icon nftmax-svg-icon__v3">
                        <svg
                          className="nftmax-svg-icon"
                          viewBox="0 0 13 14"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M10.2704 9.42604C9.70288 9.42584 9.14549 9.27516 8.65433 8.98917C8.16317 9.27516 7.60578 9.42584 7.03831 9.42604H5.96096C5.39271 9.42532 4.83491 9.27227 4.34494 8.98267C3.85499 9.27232 3.29717 9.42537 2.72891 9.42604H2.19024C1.62228 9.42585 1.06457 9.27377 0.574219 8.98538V11.5942C0.574219 12.0254 0.744478 12.439 1.04754 12.744C1.3506 13.0489 1.76165 13.2202 2.19024 13.2202H10.809C11.2376 13.2202 11.6487 13.0489 11.9517 12.744C12.2548 12.439 12.425 12.0254 12.425 11.5942V8.98592C11.9346 9.27391 11.3769 9.42578 10.809 9.42604H10.2704Z"></path>
                          <path d="M9.20833 0V3.24641H8.125V0H4.875V3.24641H3.79167V0H1.19167L0.013 5.29327L0 5.95175C0 6.52575 0.228273 7.07624 0.634602 7.48212C1.04093 7.888 1.59203 8.11602 2.16667 8.11602H2.70833C3.01703 8.11503 3.32189 8.04774 3.60227 7.91872C3.88264 7.78971 4.13197 7.60198 4.33333 7.36827C4.5347 7.60198 4.78402 7.78971 5.0644 7.91872C5.34477 8.04774 5.64964 8.11503 5.95833 8.11602H7.04167C7.34923 8.11618 7.65329 8.05077 7.93352 7.92415C8.21375 7.79753 8.4637 7.61263 8.66667 7.38179C8.86963 7.61263 9.11958 7.79753 9.39981 7.92415C9.68004 8.05077 9.9841 8.11618 10.2917 8.11602H10.8333C11.408 8.11602 11.9591 7.888 12.3654 7.48212C12.7717 7.07624 13 6.52575 13 5.95175V5.41068L11.8083 0H9.20833Z"></path>
                        </svg>
                      </span>
                      <span className="menu-bar__name">Team</span>
                    </span>
                  </Link>
                </li>
                <li
                  className={`${
                    currentPath === "/staking-income" ? "active" : ""
                  }`}
                >
                  <Link to="/staking-income">
                    <span className="menu-bar__text">
                      <span className="nftmax-menu-icon nftmax-svg-icon__v4">
                        <svg
                          className="nftmax-svg-icon"
                          viewBox="0 0 16 13"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M14.0764 6.57306C13.201 6.57306 12.4115 6.56813 11.6219 6.57503C10.998 6.57996 10.8122 6.76339 10.8031 7.37187C10.7981 7.74959 10.7971 8.12828 10.8062 8.506C10.8183 9.04051 11.0303 9.25353 11.5745 9.25945C12.3812 9.26734 13.1879 9.26142 14.0016 9.26142C14.0016 10.1164 14.0905 10.9547 13.9754 11.7663C13.8704 12.5099 13.1172 12.9902 12.2933 12.9922C8.78993 13.0011 5.28652 13.003 1.7831 12.9912C0.778516 12.9873 0.0192745 12.2476 0.0122071 11.2713C-0.00394697 9.0346 -0.00394697 6.79889 0.0122071 4.56221C0.0192745 3.57602 0.773468 2.84623 1.78108 2.84327C5.2845 2.83341 8.78791 2.8344 12.2913 2.84327C13.3131 2.84525 14.0481 3.55333 14.0743 4.54249C14.0905 5.19633 14.0764 5.85215 14.0764 6.57306Z"></path>
                          <path d="M12.8989 2.62038C12.7232 2.62038 12.5758 2.61052 12.4294 2.62235C12.0619 2.65095 11.9468 2.50697 11.9498 2.13714C11.9559 1.16772 11.5136 0.8403 10.5545 1.01486C7.72955 1.52866 4.90561 2.04247 2.07764 2.53852C1.30931 2.67265 0.558145 2.80578 0.0351562 3.54839C0.0927052 2.58093 0.718676 1.86693 1.70912 1.68152C4.7784 1.10854 7.85171 0.557262 10.9281 0.0227454C11.6085 -0.0955978 12.1295 0.258446 12.5162 0.783101C12.9221 1.3334 12.9746 1.95963 12.8989 2.62038Z"></path>
                          <path d="M13.0971 9.03944C12.5933 9.03944 12.0895 9.03747 11.5857 9.04043C11.2142 9.0424 11.0234 8.87475 11.0274 8.50394C11.0314 8.11045 11.0304 7.71696 11.0284 7.32347C11.0264 6.9566 11.2061 6.78895 11.5867 6.79191C12.5944 6.7998 13.602 6.80177 14.6106 6.79289C14.9922 6.78994 15.1699 6.95364 15.1669 7.32248C15.1628 7.71597 15.1618 8.10946 15.1659 8.50295C15.1699 8.87179 14.9872 9.0424 14.6106 9.03747C14.1068 9.03155 13.603 9.0355 13.0992 9.0355C13.0971 9.03648 13.0971 9.03846 13.0971 9.03944ZM13.8645 7.42406C13.6848 7.64299 13.4788 7.79191 13.502 7.88856C13.5373 8.03451 13.7322 8.14398 13.8594 8.26824C13.9755 8.14891 14.1674 8.03944 14.1845 7.90828C14.1997 7.79092 14.023 7.6499 13.8645 7.42406Z"></path>
                        </svg>
                      </span>
                      <span className="menu-bar__name">Staking Reward</span>
                    </span>
                  </Link>
                </li>
                <li
                  className={`${
                    currentPath === "/level-income" ? "active" : ""
                  }`}
                >
                  <Link to="/level-income">
                    <span className="menu-bar__text">
                      <span className="nftmax-menu-icon nftmax-svg-icon__v4">
                        <svg
                          className="nftmax-svg-icon"
                          viewBox="0 0 16 13"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M14.0764 6.57306C13.201 6.57306 12.4115 6.56813 11.6219 6.57503C10.998 6.57996 10.8122 6.76339 10.8031 7.37187C10.7981 7.74959 10.7971 8.12828 10.8062 8.506C10.8183 9.04051 11.0303 9.25353 11.5745 9.25945C12.3812 9.26734 13.1879 9.26142 14.0016 9.26142C14.0016 10.1164 14.0905 10.9547 13.9754 11.7663C13.8704 12.5099 13.1172 12.9902 12.2933 12.9922C8.78993 13.0011 5.28652 13.003 1.7831 12.9912C0.778516 12.9873 0.0192745 12.2476 0.0122071 11.2713C-0.00394697 9.0346 -0.00394697 6.79889 0.0122071 4.56221C0.0192745 3.57602 0.773468 2.84623 1.78108 2.84327C5.2845 2.83341 8.78791 2.8344 12.2913 2.84327C13.3131 2.84525 14.0481 3.55333 14.0743 4.54249C14.0905 5.19633 14.0764 5.85215 14.0764 6.57306Z"></path>
                          <path d="M12.8989 2.62038C12.7232 2.62038 12.5758 2.61052 12.4294 2.62235C12.0619 2.65095 11.9468 2.50697 11.9498 2.13714C11.9559 1.16772 11.5136 0.8403 10.5545 1.01486C7.72955 1.52866 4.90561 2.04247 2.07764 2.53852C1.30931 2.67265 0.558145 2.80578 0.0351562 3.54839C0.0927052 2.58093 0.718676 1.86693 1.70912 1.68152C4.7784 1.10854 7.85171 0.557262 10.9281 0.0227454C11.6085 -0.0955978 12.1295 0.258446 12.5162 0.783101C12.9221 1.3334 12.9746 1.95963 12.8989 2.62038Z"></path>
                          <path d="M13.0971 9.03944C12.5933 9.03944 12.0895 9.03747 11.5857 9.04043C11.2142 9.0424 11.0234 8.87475 11.0274 8.50394C11.0314 8.11045 11.0304 7.71696 11.0284 7.32347C11.0264 6.9566 11.2061 6.78895 11.5867 6.79191C12.5944 6.7998 13.602 6.80177 14.6106 6.79289C14.9922 6.78994 15.1699 6.95364 15.1669 7.32248C15.1628 7.71597 15.1618 8.10946 15.1659 8.50295C15.1699 8.87179 14.9872 9.0424 14.6106 9.03747C14.1068 9.03155 13.603 9.0355 13.0992 9.0355C13.0971 9.03648 13.0971 9.03846 13.0971 9.03944ZM13.8645 7.42406C13.6848 7.64299 13.4788 7.79191 13.502 7.88856C13.5373 8.03451 13.7322 8.14398 13.8594 8.26824C13.9755 8.14891 14.1674 8.03944 14.1845 7.90828C14.1997 7.79092 14.023 7.6499 13.8645 7.42406Z"></path>
                        </svg>
                      </span>
                      <span className="menu-bar__name">Aggregator Reward</span>
                    </span>
                  </Link>
                </li>
                <li
                  className={`${
                    currentPath === "/heritage-income" ? "active" : ""
                  }`}
                >
                  <Link to="/heritage-income">
                    <span className="menu-bar__text">
                      <span className="nftmax-menu-icon nftmax-svg-icon__v5">
                        <svg
                          className="nftmax-svg-icon"
                          viewBox="0 0 15 15"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M14.1755 7.88376L11.9493 9.56253L12.7948 12.266C12.9314 12.6853 12.9331 13.1389 12.7997 13.5593C12.6663 13.9797 12.4049 14.3444 12.0544 14.5988C11.7099 14.8615 11.2925 15.0022 10.8643 15C10.436 14.9978 10.02 14.8528 9.67807 14.5866L7.50125 12.9323L5.3238 14.5846C4.97996 14.8458 4.56476 14.9876 4.13792 14.9898C3.71108 14.9919 3.29457 14.8542 2.94827 14.5966C2.60197 14.3389 2.34373 13.9745 2.21066 13.5557C2.0776 13.1369 2.07657 12.6854 2.20772 12.266L3.05318 9.56253L0.826957 7.88376C0.483556 7.62452 0.22828 7.25987 0.0975914 6.84188C-0.0330973 6.4239 -0.0325136 5.97396 0.0992584 5.55633C0.23103 5.13871 0.487251 4.77476 0.831323 4.51648C1.17539 4.25819 1.58972 4.11878 2.01511 4.11815H4.74974L5.57957 1.44762C5.71006 1.02726 5.96649 0.660548 6.31187 0.400372C6.65724 0.140196 7.07372 0 7.50125 0C7.92878 0 8.34526 0.140196 8.69064 0.400372C9.03601 0.660548 9.29244 1.02726 9.42293 1.44762L10.2528 4.11815H12.9849C13.4103 4.11878 13.8246 4.25819 14.1687 4.51648C14.5127 4.77476 14.769 5.13871 14.9007 5.55633C15.0325 5.97396 15.0331 6.4239 14.9024 6.84188C14.7717 7.25987 14.5164 7.62452 14.173 7.88376H14.1755Z"></path>
                        </svg>
                      </span>
                      <span className="menu-bar__name">Cold Reward</span>
                    </span>
                  </Link>
                </li>
                <li
                  className={`${
                    currentPath === "/community-income" ? "active" : ""
                  }`}
                >
                  <Link to="/community-income">
                    <span className="menu-bar__text">
                      <span className="nftmax-menu-icon nftmax-svg-icon__v6">
                        <svg
                          className="nftmax-svg-icon"
                          viewBox="0 0 16 13"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M13.7481 5.26312C13.5414 5.97928 13.3729 6.66044 13.1345 7.31719C13.0759 7.47914 12.7927 7.65899 12.6104 7.66062C10.4807 7.68503 8.3509 7.67689 6.22115 7.67771C5.90621 7.67771 5.7052 7.52227 5.63277 7.22116C5.29585 5.81082 4.95567 4.4013 4.63422 2.9877C4.53656 2.56045 4.73106 2.29677 5.14285 2.29433C6.87872 2.28294 8.61377 2.28945 10.3928 2.28945C10.3716 3.2115 10.6231 4.01636 11.3205 4.63079C12.0049 5.23464 12.813 5.41531 13.7481 5.26312Z"></path>
                          <path d="M5.19379 8.17081C6.62692 8.17081 8.03482 8.17081 9.44271 8.17081C10.364 8.17081 11.286 8.16837 12.2072 8.17162C12.6361 8.17325 12.8803 8.36531 12.8917 8.69735C12.9039 9.04729 12.6524 9.25969 12.2056 9.25969C9.7658 9.26132 7.3268 9.26132 4.88699 9.25969C4.393 9.25888 4.25547 9.13925 4.14723 8.63957C3.68336 6.49598 3.21623 4.3524 2.76131 2.20718C2.70759 1.9549 2.60912 1.8133 2.34952 1.73354C1.75462 1.55125 1.17112 1.33152 0.584356 1.124C0.0911849 0.949842 -0.0911093 0.683725 0.0423561 0.34111C0.174194 0.00256369 0.46147 -0.0828867 0.929413 0.0798759C1.65859 0.332972 2.38126 0.605599 3.11694 0.834281C3.48804 0.949842 3.66545 1.15981 3.74439 1.53579C4.19362 3.66879 4.66075 5.79854 5.12218 7.92911C5.13683 7.9934 5.16043 8.05687 5.19379 8.17081Z"></path>
                          <path d="M10.7344 2.61573C10.7271 1.28596 11.8054 0.206025 13.1352 0.210094C14.4381 0.214164 15.5107 1.29654 15.5082 2.60596C15.5066 3.91213 14.4291 4.99125 13.1246 4.9945C11.82 4.99694 10.7417 3.92434 10.7344 2.61573ZM14.1402 1.55533C13.7089 2.01839 13.2849 2.47168 12.782 3.01124C12.5411 2.6849 12.3474 2.42285 12.1252 2.12174C11.9022 2.35612 11.7549 2.50993 11.6589 2.61084C12.0666 3.03159 12.4564 3.43442 12.817 3.80552C13.4013 3.21958 14.0027 2.61654 14.6554 1.96223C14.5488 1.8776 14.421 1.77587 14.2932 1.67414C14.2533 1.64241 14.2118 1.61067 14.1402 1.55533Z"></path>
                          <path d="M11.6107 9.7032C12.4571 9.69506 13.1464 10.3599 13.1618 11.1998C13.1781 12.0535 12.4847 12.7542 11.6245 12.7542C10.7847 12.7542 10.097 12.0714 10.0938 11.2348C10.0913 10.3933 10.7668 9.71134 11.6107 9.7032ZM12.3024 11.2234C12.3008 10.8523 12.0103 10.552 11.6441 10.5439C11.2648 10.5349 10.9483 10.8482 10.9507 11.2307C10.9531 11.5978 11.2486 11.9029 11.6107 11.9119C11.9899 11.9217 12.3041 11.6091 12.3024 11.2234Z"></path>
                          <path d="M5.41555 12.7526C4.57082 12.7461 3.8921 12.0665 3.89454 11.2291C3.89698 10.364 4.58221 9.69181 5.45218 9.70239C6.29122 9.71216 6.96506 10.4039 6.95448 11.2454C6.94471 12.0877 6.25867 12.7591 5.41555 12.7526ZM6.1016 11.2299C6.10241 10.845 5.78828 10.5349 5.40579 10.5447C5.04527 10.5536 4.73846 10.8661 4.73765 11.2234C4.73683 11.5831 5.0412 11.8981 5.40009 11.9103C5.78014 11.9241 6.10079 11.6132 6.1016 11.2299Z"></path>
                        </svg>
                      </span>
                      <span className="menu-bar__name">Mining Reward</span>
                    </span>
                  </Link>
                </li>
                <li
                  className={`${
                    currentPath === "/community-royalty" ? "active" : ""
                  }`}
                >
                  <Link to="/community-royalty">
                    <span className="menu-bar__text">
                      <span className="nftmax-menu-icon nftmax-svg-icon__v6">
                        <svg
                          className="nftmax-svg-icon"
                          viewBox="0 0 16 13"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M13.7481 5.26312C13.5414 5.97928 13.3729 6.66044 13.1345 7.31719C13.0759 7.47914 12.7927 7.65899 12.6104 7.66062C10.4807 7.68503 8.3509 7.67689 6.22115 7.67771C5.90621 7.67771 5.7052 7.52227 5.63277 7.22116C5.29585 5.81082 4.95567 4.4013 4.63422 2.9877C4.53656 2.56045 4.73106 2.29677 5.14285 2.29433C6.87872 2.28294 8.61377 2.28945 10.3928 2.28945C10.3716 3.2115 10.6231 4.01636 11.3205 4.63079C12.0049 5.23464 12.813 5.41531 13.7481 5.26312Z"></path>
                          <path d="M5.19379 8.17081C6.62692 8.17081 8.03482 8.17081 9.44271 8.17081C10.364 8.17081 11.286 8.16837 12.2072 8.17162C12.6361 8.17325 12.8803 8.36531 12.8917 8.69735C12.9039 9.04729 12.6524 9.25969 12.2056 9.25969C9.7658 9.26132 7.3268 9.26132 4.88699 9.25969C4.393 9.25888 4.25547 9.13925 4.14723 8.63957C3.68336 6.49598 3.21623 4.3524 2.76131 2.20718C2.70759 1.9549 2.60912 1.8133 2.34952 1.73354C1.75462 1.55125 1.17112 1.33152 0.584356 1.124C0.0911849 0.949842 -0.0911093 0.683725 0.0423561 0.34111C0.174194 0.00256369 0.46147 -0.0828867 0.929413 0.0798759C1.65859 0.332972 2.38126 0.605599 3.11694 0.834281C3.48804 0.949842 3.66545 1.15981 3.74439 1.53579C4.19362 3.66879 4.66075 5.79854 5.12218 7.92911C5.13683 7.9934 5.16043 8.05687 5.19379 8.17081Z"></path>
                          <path d="M10.7344 2.61573C10.7271 1.28596 11.8054 0.206025 13.1352 0.210094C14.4381 0.214164 15.5107 1.29654 15.5082 2.60596C15.5066 3.91213 14.4291 4.99125 13.1246 4.9945C11.82 4.99694 10.7417 3.92434 10.7344 2.61573ZM14.1402 1.55533C13.7089 2.01839 13.2849 2.47168 12.782 3.01124C12.5411 2.6849 12.3474 2.42285 12.1252 2.12174C11.9022 2.35612 11.7549 2.50993 11.6589 2.61084C12.0666 3.03159 12.4564 3.43442 12.817 3.80552C13.4013 3.21958 14.0027 2.61654 14.6554 1.96223C14.5488 1.8776 14.421 1.77587 14.2932 1.67414C14.2533 1.64241 14.2118 1.61067 14.1402 1.55533Z"></path>
                          <path d="M11.6107 9.7032C12.4571 9.69506 13.1464 10.3599 13.1618 11.1998C13.1781 12.0535 12.4847 12.7542 11.6245 12.7542C10.7847 12.7542 10.097 12.0714 10.0938 11.2348C10.0913 10.3933 10.7668 9.71134 11.6107 9.7032ZM12.3024 11.2234C12.3008 10.8523 12.0103 10.552 11.6441 10.5439C11.2648 10.5349 10.9483 10.8482 10.9507 11.2307C10.9531 11.5978 11.2486 11.9029 11.6107 11.9119C11.9899 11.9217 12.3041 11.6091 12.3024 11.2234Z"></path>
                          <path d="M5.41555 12.7526C4.57082 12.7461 3.8921 12.0665 3.89454 11.2291C3.89698 10.364 4.58221 9.69181 5.45218 9.70239C6.29122 9.71216 6.96506 10.4039 6.95448 11.2454C6.94471 12.0877 6.25867 12.7591 5.41555 12.7526ZM6.1016 11.2299C6.10241 10.845 5.78828 10.5349 5.40579 10.5447C5.04527 10.5536 4.73846 10.8661 4.73765 11.2234C4.73683 11.5831 5.0412 11.8981 5.40009 11.9103C5.78014 11.9241 6.10079 11.6132 6.1016 11.2299Z"></path>
                        </svg>
                      </span>
                      <span className="menu-bar__name">Rank Reward</span>
                    </span>
                  </Link>
                </li>
                <li
                  className={`${currentPath === "/wtihdrawal" ? "active" : ""}`}
                >
                  <Link to="/wtihdrawal">
                    <span className="menu-bar__text">
                      <span className="nftmax-menu-icon nftmax-svg-icon__v7">
                        <svg
                          className="nftmax-svg-icon"
                          viewBox="0 0 14 13"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M3.79296 0C4.44956 0.010169 5.09184 0.192671 5.65493 0.529075C6.21803 0.86548 6.682 1.34387 7 1.91595C7.318 1.34387 7.78197 0.86548 8.34507 0.529075C8.90816 0.192671 9.55044 0.010169 10.207 0C11.2537 0.0452807 12.2399 0.501197 12.9501 1.26814C13.6602 2.03509 14.0367 3.05073 13.9972 4.09316C13.9972 8.02666 7.60875 12.5698 7.33703 12.7625L7 13L6.66297 12.7625C6.39125 12.5709 0.00282288 8.02666 0.00282288 4.09316C-0.0366831 3.05073 0.339771 2.03509 1.04994 1.26814C1.76011 0.501197 2.74626 0.0452807 3.79296 0Z"></path>
                        </svg>
                      </span>
                      <span className="menu-bar__name">Withdrawal</span>
                    </span>
                  </Link>
                </li>
                <li className={`${currentPath === "/ledger" ? "active" : ""}`}>
                  <Link to="/ledger">
                    <span className="menu-bar__text">
                      <span className="nftmax-menu-icon nftmax-svg-icon__v7">
                        <svg
                          className="nftmax-svg-icon"
                          viewBox="0 0 14 13"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M3.79296 0C4.44956 0.010169 5.09184 0.192671 5.65493 0.529075C6.21803 0.86548 6.682 1.34387 7 1.91595C7.318 1.34387 7.78197 0.86548 8.34507 0.529075C8.90816 0.192671 9.55044 0.010169 10.207 0C11.2537 0.0452807 12.2399 0.501197 12.9501 1.26814C13.6602 2.03509 14.0367 3.05073 13.9972 4.09316C13.9972 8.02666 7.60875 12.5698 7.33703 12.7625L7 13L6.66297 12.7625C6.39125 12.5709 0.00282288 8.02666 0.00282288 4.09316C-0.0366831 3.05073 0.339771 2.03509 1.04994 1.26814C1.76011 0.501197 2.74626 0.0452807 3.79296 0Z"></path>
                        </svg>
                      </span>
                      <span className="menu-bar__name">Ledger</span>
                    </span>
                  </Link>
                </li>
                <li className={`${currentPath === "/unstake" ? "active" : ""}`}>
                  <Link to="/unstake">
                    <span className="menu-bar__text">
                      <span className="nftmax-menu-icon nftmax-svg-icon__v7">
                        <svg
                          className="nftmax-svg-icon"
                          viewBox="0 0 14 13"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M3.79296 0C4.44956 0.010169 5.09184 0.192671 5.65493 0.529075C6.21803 0.86548 6.682 1.34387 7 1.91595C7.318 1.34387 7.78197 0.86548 8.34507 0.529075C8.90816 0.192671 9.55044 0.010169 10.207 0C11.2537 0.0452807 12.2399 0.501197 12.9501 1.26814C13.6602 2.03509 14.0367 3.05073 13.9972 4.09316C13.9972 8.02666 7.60875 12.5698 7.33703 12.7625L7 13L6.66297 12.7625C6.39125 12.5709 0.00282288 8.02666 0.00282288 4.09316C-0.0366831 3.05073 0.339771 2.03509 1.04994 1.26814C1.76011 0.501197 2.74626 0.0452807 3.79296 0Z"></path>
                        </svg>
                      </span>
                      <span className="menu-bar__name">Unstake</span>
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/assets/onmax_affiliate_presenataion.pdf"
                    target="_blank"
                    download
                  >
                    <span className="menu-bar__text">
                      <span className="nftmax-menu-icon nftmax-svg-icon__v7">
                        <svg
                          className="nftmax-svg-icon"
                          viewBox="0 0 14 13"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M3.79296 0C4.44956 0.010169 5.09184 0.192671 5.65493 0.529075C6.21803 0.86548 6.682 1.34387 7 1.91595C7.318 1.34387 7.78197 0.86548 8.34507 0.529075C8.90816 0.192671 9.55044 0.010169 10.207 0C11.2537 0.0452807 12.2399 0.501197 12.9501 1.26814C13.6602 2.03509 14.0367 3.05073 13.9972 4.09316C13.9972 8.02666 7.60875 12.5698 7.33703 12.7625L7 13L6.66297 12.7625C6.39125 12.5709 0.00282288 8.02666 0.00282288 4.09316C-0.0366831 3.05073 0.339771 2.03509 1.04994 1.26814C1.76011 0.501197 2.74626 0.0452807 3.79296 0Z"></path>
                        </svg>
                      </span>
                      <span className="menu-bar__name">Presentation</span>
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="https://help.onmaxyield.com/" target="_blank">
                    <span className="menu-bar__text">
                      <span className="nftmax-menu-icon nftmax-svg-icon__v7">
                        <svg
                          className="nftmax-svg-icon"
                          viewBox="0 0 14 13"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M3.79296 0C4.44956 0.010169 5.09184 0.192671 5.65493 0.529075C6.21803 0.86548 6.682 1.34387 7 1.91595C7.318 1.34387 7.78197 0.86548 8.34507 0.529075C8.90816 0.192671 9.55044 0.010169 10.207 0C11.2537 0.0452807 12.2399 0.501197 12.9501 1.26814C13.6602 2.03509 14.0367 3.05073 13.9972 4.09316C13.9972 8.02666 7.60875 12.5698 7.33703 12.7625L7 13L6.66297 12.7625C6.39125 12.5709 0.00282288 8.02666 0.00282288 4.09316C-0.0366831 3.05073 0.339771 2.03509 1.04994 1.26814C1.76011 0.501197 2.74626 0.0452807 3.79296 0Z"></path>
                        </svg>
                      </span>
                      <span className="menu-bar__name">Help</span>
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="admin-menu__two mg-top-50">
            <h4 className="admin-menu__title nftmax-scolor">Settings</h4>
            <div className="menu-bar">
              <ul className="menu-bar__one">
                <li>
                  <Link to="/profile">
                    <span className="menu-bar__text">
                      <span className="nftmax-menu-icon nftmax-svg-icon__v10">
                        <svg
                          className="nftmax-svg-icon"
                          viewBox="0 0 15 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M10.8692 11.6667H4.13085C3.03569 11.668 1.98576 12.1036 1.21136 12.878C0.436961 13.6524 0.00132319 14.7023 0 15.7975V20H15.0001V15.7975C14.9987 14.7023 14.5631 13.6524 13.7887 12.878C13.0143 12.1036 11.9644 11.668 10.8692 11.6667Z"></path>
                          <path d="M7.49953 10C10.261 10 12.4995 7.76145 12.4995 5.00002C12.4995 2.23858 10.261 0 7.49953 0C4.7381 0 2.49951 2.23858 2.49951 5.00002C2.49951 7.76145 4.7381 10 7.49953 10Z"></path>
                        </svg>
                      </span>
                      <span className="menu-bar__name">Portfolio</span>{" "}
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="logout-button">
            <a
              className="nftmax-btn primary"
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#logout_modal"
            >
              <div className="logo-button__icon">
                <img src="assets/img/logout.png" alt="#" />
              </div>
              <span className="menu-bar__name">Signout</span>
            </a>
          </div>
        </div>
      </div> */}
    </>
  );
}
