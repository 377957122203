import React, { useContext, useState } from "react";
import Sidebar from "./Comman/Sidebar";
import Header from "./Comman/Header";
import { OnmaxContext } from "../OnmaxContext";
import axios from "axios";

export default function EditProfile() {
  const { toastError, toastSuccess, dbuser, account,getuserDB } =
    useContext(OnmaxContext);
  const [usrname, setusrname] = useState(dbuser !== null ? dbuser.name : null);
  const updateData = async () => {
    if (!account) {
      toastError("Connect First!");
      return;
    }
    if (!usrname) {
      toastError("Enter Username");
      return;
    }
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "user",
        submethod: "edit",
        key:process.env.REACT_APP_KEY,
        name: usrname,
        address:account
      })
      .then((res) => {
        getuserDB()
        if (res.data.error) {
          toastError(res.data.error);
          return;
        }
        toastSuccess("Username updated");
      });
  };
  return (
    <>
      <div className="body-bg">
        <Sidebar />
        <Header />
        <section className="">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5 col-5 nftmax-main__colum">
                <div className="nftmax-body">
                  <div className="nftmax-dsinner">
                    <div className="nftmax-inner__heading">
                      <h2 className="nftmax-inner__page-title">Edit Profile</h2>
                    </div>
                    <div className="nftmax-personals">
                      <div className="row d-flex justify-content-center">
                        <div className="col-lg-8 col-md-8 col-8 align-items-center nftmax-personals__content">
                          <div className="nftmax-ptab">
                            <div className="nftmax-ptabs__inner mt-4">
                              <div className="tab-content" id="nav-tabContent">
                                <div
                                  className="tab-pane fade show active"
                                  id="id1"
                                  role="tabpanel"
                                >
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="nftmax-ptabs__separa">
                                        <div className="nftmax-ptabs__form-main">
                                          <div className="nftmax__item-form--group">
                                            <label className="nftmax__item-label">
                                              User Name
                                            </label>
                                            <input
                                              className="nftmax__item-input"
                                              type="text"
                                              placeholder="Enter Username"
                                              value={usrname}
                                              onChange={(e) =>
                                                setusrname(e.target.value)
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="nftmax__item-button--group nftmax__ptabs-bottom">
                                    <button className="nftmax__item-button--single nftmax-btn nftmax-btn__bordered bg radius " onClick={()=>updateData()}>
                                      Change
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
